
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.page-about {
  &__title {
    margin-bottom: 20px;
  }
}
